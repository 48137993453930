import revive_payload_client_4sVQNw7RlN from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_klhsrycjcK from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/fontawesome.js";
import aos_Rx1DQ3If9A from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/aos.js";
import axios_sVCuMR6hEC from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/axios.js";
import maska_r8BHBbSmHt from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/maska.js";
import primevue_TdXjRgL1MA from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/primevue.js";
import settings_2MnfoqQ5ue from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/settings.js";
import v_calendar_oQ1bZ1yFoH from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/v-calendar.js";
import vueMoney_ZBYVKc6mp0 from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/plugins/vueMoney.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_klhsrycjcK,
  aos_Rx1DQ3If9A,
  axios_sVCuMR6hEC,
  maska_r8BHBbSmHt,
  primevue_TdXjRgL1MA,
  settings_2MnfoqQ5ue,
  v_calendar_oQ1bZ1yFoH,
  vueMoney_ZBYVKc6mp0
]