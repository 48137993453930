import AOS from "aos";
import "aos/dist/aos.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("page:finish", () => {
    AOS.init({
      // Configurações do AOS (personalize conforme necessário)
      duration: 1000, // Duração da animação (em milissegundos)
      easing: "ease-in-out", // Tipo de easing
      once: true, // Se verdadeiro, a animação acontece apenas uma vez
    });
  });
});
