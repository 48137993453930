import { default as indexv3dJTR6HtZMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/agendamento/index.vue?macro=true";
import { default as registerQK59xBWclPMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/auth/register.vue?macro=true";
import { default as indexXWuLsXHJkDMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/blog/index.vue?macro=true";
import { default as dashboard7TsEyY4fX3Meta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/dashboard.vue?macro=true";
import { default as _91id_93MW4MR3SeYuMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/[id].vue?macro=true";
import { default as criarF2x3f9uYJbMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/criar.vue?macro=true";
import { default as indexu0Vh3tYE8OMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/index.vue?macro=true";
import { default as indexySycTS8cjxMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/feedback/index.vue?macro=true";
import { default as index7ZaU9FFSRqMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/index.vue?macro=true";
import { default as loginn0Xs8pm9yRMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/login.vue?macro=true";
import { default as listBsDafuZCnGMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/pagamento/list.vue?macro=true";
import { default as _91id_936VqmRZgYGEMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/pagamento/sessao/[id].vue?macro=true";
import { default as _91slug_93StAviH69VTMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/post/[slug].vue?macro=true";
import { default as lastC67lhKue63Meta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/post/last.vue?macro=true";
import { default as indexuI4rJuoGLEMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/postback/index.vue?macro=true";
import { default as _91id_93YAJNptXeKvMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologa/[id].vue?macro=true";
import { default as indexz2AUaoTs72Meta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologa/index.vue?macro=true";
import { default as indexS4J2lX7at4Meta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologas/index.vue?macro=true";
import { default as Recoverc3nKReYjosMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/Recover.vue?macro=true";
import { default as indexMLA1nLfEnMMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/sobre-mim/index.vue?macro=true";
import { default as of_usezinvCAr7OBMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/terms/of_use.vue?macro=true";
import { default as cadastrouZmDC2exvLMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/user/cadastro.vue?macro=true";
import { default as perfilUyqddpC1czMeta } from "/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/user/perfil.vue?macro=true";
export default [
  {
    name: "agendamento",
    path: "/agendamento",
    meta: indexv3dJTR6HtZMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/agendamento/index.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerQK59xBWclPMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/auth/register.vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexXWuLsXHJkDMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/blog/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard7TsEyY4fX3Meta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/dashboard.vue")
  },
  {
    name: "diario-id",
    path: "/diario/:id()",
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/[id].vue")
  },
  {
    name: "diario-criar",
    path: "/diario/criar",
    meta: criarF2x3f9uYJbMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/criar.vue")
  },
  {
    name: "diario",
    path: "/diario",
    meta: indexu0Vh3tYE8OMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/diario/index.vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/feedback/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7ZaU9FFSRqMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginn0Xs8pm9yRMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/login.vue")
  },
  {
    name: "pagamento-list",
    path: "/pagamento/list",
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/pagamento/list.vue")
  },
  {
    name: "pagamento-sessao-id",
    path: "/pagamento/sessao/:id()",
    meta: _91id_936VqmRZgYGEMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/pagamento/sessao/[id].vue")
  },
  {
    name: "post-slug",
    path: "/post/:slug()",
    meta: _91slug_93StAviH69VTMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/post/[slug].vue")
  },
  {
    name: "post-last",
    path: "/post/last",
    meta: lastC67lhKue63Meta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/post/last.vue")
  },
  {
    name: "postback",
    path: "/postback",
    meta: indexuI4rJuoGLEMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/postback/index.vue")
  },
  {
    name: "psicologa-id",
    path: "/psicologa/:id()",
    meta: _91id_93YAJNptXeKvMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologa/[id].vue")
  },
  {
    name: "psicologa",
    path: "/psicologa",
    meta: indexz2AUaoTs72Meta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologa/index.vue")
  },
  {
    name: "psicologas",
    path: "/psicologas",
    meta: indexS4J2lX7at4Meta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/psicologas/index.vue")
  },
  {
    name: "Recover",
    path: "/Recover",
    meta: Recoverc3nKReYjosMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/Recover.vue")
  },
  {
    name: "sobre-mim",
    path: "/sobre-mim",
    meta: indexMLA1nLfEnMMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/sobre-mim/index.vue")
  },
  {
    name: "terms-of_use",
    path: "/terms/of_use",
    meta: of_usezinvCAr7OBMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/terms/of_use.vue")
  },
  {
    name: "user-cadastro",
    path: "/user/cadastro",
    meta: cadastrouZmDC2exvLMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/user/cadastro.vue")
  },
  {
    name: "user-perfil",
    path: "/user/perfil",
    meta: perfilUyqddpC1czMeta || {},
    component: () => import("/home/laboratoriodoser/htdocs/laboratoriodoser.com.br/pages/user/perfil.vue")
  }
]